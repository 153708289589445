<template>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">

        <div class="flex flex-col sm:flex-row justify-between items-center p-3 border-b border-gray-200 dark:border-dark-5" style="border-top-left-radius: 6px; border-top-right-radius: 6px;  border-top-color: #FBC02D; border-top-width: 6px;">
          <h2 class="mr-auto text-lg font-bold">Reporte Gastos Financieros</h2>
          <span class="inline-flex items-center justify-center px-2 py-1 text-sm font-bold leading-none text-dark-3 bg-gray-300 rounded">Total registros: {{ totalRecords }}</span>
        </div>

        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-12">
            <DataTable
              :value="dataFinancialExpenses"
              :paginator="true"
              :rows="20"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[10,20,50,100,250,500]"
              responsiveLayout="scroll"
              currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
              showGridlines
              ref="dt"
              class="p-datatable-sm p-2 text-sm"
            >
              <template #header class="relative">
                <FiltersReportFinancialExpenses v-model="filters" @search="onSearch" @downloadExcel="handleDownloadXls"/>
              </template>
              <ColumnGroup type="header">
                <Row>
                  <Column header="# Obligación" :rowspan="3" />
                  <Column header="Línea de Crédito" :rowspan="3" />
                  <Column header="Fecha Ultimo Pago" :rowspan="3" style="min-width: 100px"/>
                </Row>
                <Row>
                  <Column header="BANCO BBVA" :colspan="4" />
                  <Column header="BANCO COLPATRIA" :colspan="4" />
                  <Column header="BANCO DAVIVIENDA" :colspan="4" />
                  <Column header="BANCO DE BOGOTA" :colspan="4" />
                  <Column header="BANCO DE OCCIDENTE" :colspan="4" />
                  <Column header="BANCO ITAU CORPOBANCA" :colspan="4" />
                  <Column header="BANCOLOMBIA" :colspan="4" />
                  <Column header="BANCOOMEVA" :colspan="4" />
                </Row>
                <Row>
                  <Column header="Intereses Corriente"></Column>
                  <Column header="Intereses Mora"></Column>
                  <Column header="Papelería"></Column>
                  <Column header="Comisión"></Column>
                  <Column header="Intereses Corriente"></Column>
                  <Column header="Intereses Mora"></Column>
                  <Column header="Papelería"></Column>
                  <Column header="Comisión"></Column>
                  <Column header="Intereses Corriente"></Column>
                  <Column header="Intereses Mora"></Column>
                  <Column header="Papelería"></Column>
                  <Column header="Comisión"></Column>
                  <Column header="Intereses Corriente"></Column>
                  <Column header="Intereses Mora"></Column>
                  <Column header="Papelería"></Column>
                  <Column header="Comisión"></Column>
                  <Column header="Intereses Corriente"></Column>
                  <Column header="Intereses Mora"></Column>
                  <Column header="Papelería"></Column>
                  <Column header="Comisión"></Column>
                  <Column header="Intereses Corriente"></Column>
                  <Column header="Intereses Mora"></Column>
                  <Column header="Papelería"></Column>
                  <Column header="Comisión"></Column>
                  <Column header="Intereses Corriente"></Column>
                  <Column header="Intereses Mora"></Column>
                  <Column header="Papelería"></Column>
                  <Column header="Comisión"></Column>
                  <Column header="Intereses Corriente"></Column>
                  <Column header="Intereses Mora"></Column>
                  <Column header="Papelería"></Column>
                  <Column header="Comisión"></Column>
                </Row>
              </ColumnGroup>
              <Column field="obligationNumber"></Column>
              <Column field="creditLineName"></Column>
              <Column field="ultimoPago"></Column>
              <Column field="BANCO BBVA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO BBVA']) ? '$0,00' : $h.formatCurrency(data['BANCO BBVA']['INTERESES CORRIENTES'], 2)}}
                </template>
              </Column>
              <Column field="BANCO BBVA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO BBVA']) ? '$0,00' : $h.formatCurrency(data['BANCO BBVA']['INTERESES MORA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO BBVA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO BBVA']) ? '$0,00' : $h.formatCurrency(data['BANCO BBVA']['PAPELERIA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO BBVA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO BBVA']) ? '$0,00' : $h.formatCurrency(data['BANCO BBVA']['COMISION'], 2) }}
                </template>
              </Column>
              <Column field="BANCO COLPATRIA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO COLPATRIA']) ? '$0,00' : $h.formatCurrency(data['BANCO COLPATRIA']['INTERESES CORRIENTES'], 2) }}
                </template>
              </Column>
              <Column field="BANCO COLPATRIA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO COLPATRIA']) ? '$0,00' : $h.formatCurrency(data['BANCO COLPATRIA']['INTERESES MORA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO COLPATRIA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO COLPATRIA']) ? '$0,00' : $h.formatCurrency(data['BANCO COLPATRIA']['PAPELERIA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO COLPATRIA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO COLPATRIA']) ? '$0,00' : $h.formatCurrency(data['BANCO COLPATRIA']['COMISION'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DAVIVIENDA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DAVIVIENDA']) ? '$0,00' : $h.formatCurrency(data['BANCO DAVIVIENDA']['INTERESES CORRIENTES'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DAVIVIENDA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DAVIVIENDA']) ? '$0,00' : $h.formatCurrency(data['BANCO DAVIVIENDA']['INTERESES MORA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DAVIVIENDA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DAVIVIENDA']) ? '$0,00' : $h.formatCurrency(data['BANCO DAVIVIENDA']['PAPELERIA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DAVIVIENDA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DAVIVIENDA']) ? '$0,00' : $h.formatCurrency(data['BANCO DAVIVIENDA']['COMISION'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DE BOGOTA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DE BOGOTA']) ? '$0,00' : $h.formatCurrency(data['BANCO DE BOGOTA']['INTERESES CORRIENTES'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DE BOGOTA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DE BOGOTA']) ? '$0,00' : $h.formatCurrency(data['BANCO DE BOGOTA']['INTERESES MORA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DE BOGOTA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DE BOGOTA']) ? '$0,00' : $h.formatCurrency(data['BANCO DE BOGOTA']['PAPELERIA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DE BOGOTA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DE BOGOTA']) ? '$0,00' : $h.formatCurrency(data['BANCO DE BOGOTA']['COMISION'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DE OCCIDENTE">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DE OCCIDENTE']) ? '$0,00' : $h.formatCurrency(data['BANCO DE OCCIDENTE']['INTERESES CORRIENTES'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DE OCCIDENTE">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DE OCCIDENTE']) ? '$0,00' : $h.formatCurrency(data['BANCO DE OCCIDENTE']['INTERESES MORA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DE OCCIDENTE">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DE OCCIDENTE']) ? '$0,00' : $h.formatCurrency(data['BANCO DE OCCIDENTE']['PAPELERIA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO DE OCCIDENTE">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO DE OCCIDENTE']) ? '$0,00' : $h.formatCurrency(data['BANCO DE OCCIDENTE']['COMISION'], 2) }}
                </template>
              </Column>
              <Column field="BANCO ITAU CORPOBANCA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO ITAU CORPOBANCA']) ? '$0,00' : $h.formatCurrency(data['BANCO ITAU CORPOBANCA']['INTERESES CORRIENTES'], 2) }}
                </template>
              </Column>
              <Column field="BANCO ITAU CORPOBANCA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO ITAU CORPOBANCA']) ? '$0,00' : $h.formatCurrency(data['BANCO ITAU CORPOBANCA']['INTERESES MORA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO ITAU CORPOBANCA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO ITAU CORPOBANCA']) ? '$0,00' : $h.formatCurrency(data['BANCO ITAU CORPOBANCA']['PAPELERIA'], 2) }}
                </template>
              </Column>
              <Column field="BANCO ITAU CORPOBANCA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCO ITAU CORPOBANCA']) ? '$0,00' : $h.formatCurrency(data['BANCO ITAU CORPOBANCA']['COMISION'], 2) }}
                </template>
              </Column>
              <Column field="BANCOLOMBIA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCOLOMBIA']) ? '$0,00' : $h.formatCurrency(data['BANCOLOMBIA']['INTERESES CORRIENTES'], 2) }}
                </template>
              </Column>
              <Column field="BANCOLOMBIA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCOLOMBIA']) ? '$0,00' : $h.formatCurrency(data['BANCOLOMBIA']['INTERESES MORA'], 2) }}
                </template>
              </Column>
              <Column field="BANCOLOMBIA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCOLOMBIA']) ? '$0,00' : $h.formatCurrency(data['BANCOLOMBIA']['PAPELERIA'], 2) }}
                </template>
              </Column>
              <Column field="BANCOLOMBIA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCOLOMBIA']) ? '$0,00' : $h.formatCurrency(data['BANCOLOMBIA']['COMISION'], 2) }}
                </template>
              </Column>
              <Column field="BANCOOMEVA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCOOMEVA']) ? '$0,00' : $h.formatCurrency(data['BANCOOMEVA']['INTERESES CORRIENTES'], 2) }}
                </template>
              </Column>
              <Column field="BANCOOMEVA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCOOMEVA']) ? '$0,00' : $h.formatCurrency(data['BANCOOMEVA']['INTERESES MORA'], 2) }}
                </template>
              </Column>
              <Column field="BANCOOMEVA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCOOMEVA']) ? '$0,00' : $h.formatCurrency(data['BANCOOMEVA']['PAPELERIA'], 2) }}
                </template>
              </Column>
              <Column field="BANCOOMEVA">
                <template #body="{data}">
                  {{ [undefined, null, '', "0", 0].includes(data['BANCOOMEVA']) ? '$0,00' : $h.formatCurrency(data['BANCOOMEVA']['COMISION'], 2) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

import FiltersReportFinancialExpenses from './filters'
import { onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import { useToast } from 'primevue/usetoast'
import listReportFinancialExpenses from '../../../services/reportFinancialExpenses'
import http from '../../../../../../../libs/http'
export default {
  name: 'reportPaymentsObligations',
  components: { FiltersReportFinancialExpenses },
  setup () {
    const dt = ref()
    const toast = useToast()
    const totalRecords = ref(0)
    const dataFinancialExpenses = ref([])
    const lazyParams = ref({})
    const today = new Date()
    // const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    const formatDate = dayjs(today).format('YYYY-MM-DD')

    const filters = ref({
      fecha_corte: { value: formatDate, matchMode: 'contains' },
      bankId: { value: null, matchMode: 'contains' },
      creditLineId: { value: null, matchMode: 'contains' }
    })

    const onSearch = (event) => {
      lazyParams.value.filters = filters.value
      fetchReportFinancialExpenses(event, 'filter')
    }

    /* Obtener la lista */
    const fetchReportFinancialExpenses = (event = false, typeVar) => {
      let queryParams = {
        fecha_corte: formatDate,
        current_page: 1,
        per_page: 10,
        order: 'nameBank, ASC'
      }

      if (typeVar === 'filter') {
        queryParams = {
          ...queryParams,
          fecha_corte: event.filters.fecha_corte.value ? event.filters.fecha_corte.value : null,
          creditLineId: event.filters.creditLineId.value ? event.filters.creditLineId.value : null,
          bankId: event.filters.bankId.value ? event.filters.bankId.value : null
        }
      }

      return listReportFinancialExpenses(queryParams).then(({ status, data }) => {
        if (status !== 200) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Problemas al listar los gastos financieros, consulte con el administrador del sistema', life: 3000 })
        } else {
          dataFinancialExpenses.value = data.result
          totalRecords.value = dataFinancialExpenses.value.length
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Se cargó bien la información', life: 3000 })
        }
      })
    }

    const handleDownloadXls = (event) => {
      const queryParams = {
        fecha_corte: event.filters.fecha_corte.value ? event.filters.fecha_corte.value : null,
        creditLineId: event.filters.creditLineId.value ? event.filters.creditLineId.value : null,
        bankId: event.filters.bankId.value ? event.filters.bankId.value : null
      }
      const url = `${process.env.VUE_APP_MICROSERVICE_API_AMORTIZATIONS}/reports/financial-expenses/download`
      const res = http.getUri({ url, params: queryParams })
      window.open(res, '_blank')
    }

    /**/
    onMounted(() => {
      lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value
      }

      fetchReportFinancialExpenses()
    })

    return {
      dt,
      filters,
      onSearch,
      dataFinancialExpenses,
      totalRecords,
      handleDownloadXls
    }
  }
}
</script>

<style lang="scss">
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3F4254;
  background-color: #F3F6F9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}
.symbol .symbol-badge {
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 100%;
  top: 0;
  right: 0;
}
.symbol.symbol-circle {
  border-radius: 50%;
}
.symbol.symbol-circle > img {
  border-radius: 50%;
}
.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}
.symbol.symbol-light-primary .symbol-label {
  background-color: #E1F0FF;
  color: #3699FF;
}
.symbol.symbol-light-secondary .symbol-label {
  background-color: #EBEDF3;
  color: #E4E6EF;
}
.symbol.symbol-light-success .symbol-label {
  background-color: #C9F7F5;
  color: #1BC5BD;
}
.symbol.symbol-light-info .symbol-label {
  background-color: #EEE5FF;
  color: #8950FC;
}
.symbol.symbol-light-warning .symbol-label {
  background-color: #FFF4DE;
  color: #FFA800;
}
.symbol.symbol-light-danger .symbol-label {
  background-color: #FFE2E5;
  color: #F64E60;
}
.symbol.symbol-light-light .symbol-label {
  background-color: #F3F6F9;
  color: #F3F6F9;
}
.symbol.symbol-light-dark .symbol-label {
  background-color: #D1D3E0;
  color: #181C32;
}
.symbol.symbol-light-white .symbol-label {
  background-color: #ffffff;
  color: #ffffff;
}
.symbol.symbol-30 > img {
  width: 100%;
  max-width: 40px;
  height: 40px;
}
.symbol.symbol-30 .symbol-label {
  width: 40px;
  height: 40px;
}
.symbol.symbol-30 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}
.symbol.symbol-30 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-50 > img {
  width: 100%;
  max-width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}
.symbol.symbol-50 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}
.custom-style {
  .p-dialog-header {
    color: #5E8F32 !important;
  }
}

.custom-style {
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0.75rem !important;
  }
  .p-fieldset-content {
    padding: 0 !important;
  }
}
</style>
